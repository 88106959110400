import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Images from '../Images'
import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = withDefaultRenderComponent(
  ({
    components: {
      RightSectionContainer = DefaultComponents.RightSectionContainer,
      ContainerLeft = DefaultComponents.ContainerLeft,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <RightSectionContainer>
      <Images.FirstSection />
      <ContainerLeft>
        <Headline>MANAGEMENT</Headline>
        <Brief>カタログの進化と建材情報の一元管理</Brief>
        <Article>
          今般、建築建材カタログの多様性は、日進月歩進化を遂げてきました。しかし、同業種の多メーカーによる、個々の製品に対しての異なる表現、角度の違う説明による混乱が避けられず、ユーザーである設計者、施工者に混乱を来たし、カタログの合理的な利用に問題が生じています。また、カタログでの表現の限界から、WEBカタログとの二重構造での運営を余儀なくされています。従って、本来の製品説明、製品データのハンドリングに問題が起こっているのも事実です。こう言った、メーカー様に於ける個別の製品データに対するハンドリングやユーザーの利便性の向上を妨げる問題を、Arch-LOGをご利用頂くことで飛躍的に解消することが出来ます。プロジェクト毎の建材データの一元管理はもとより、データの収蔵を、将来に渡りアーカイブ化することで、建築のライフサイクルを通した「建材データの一元管理」ができ、将来的なFM活用への可能性が広がります。
        </Article>
      </ContainerLeft>
    </RightSectionContainer>
  ),
)

/**
 */
export const SecondSection = withDefaultRenderComponent(
  ({
    components: {
      LeftSectionContainer = DefaultComponents.LeftSectionContainer,
      ContainerRight = DefaultComponents.ContainerRight,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <LeftSectionContainer>
      <Images.SecondSection />
      <ContainerRight>
        <Headline>PRESENTATION</Headline>
        <Brief>デジタルプレゼンテーション改革</Brief>
        <Article>
          昨今、設計デザイン提案をする際には、マテリアルサンプルをカットし、パネルに貼り付け、アナログ的にマテリアルボードを作成しているのが現状です。再提案の度に大変手間がかかり、同じ作業を繰り返す上、アナログ的作業となるため、単純なヒューマンエラーのリスクも常にはらんでいます。Arch-LOGをご利用いただく事で、これら業務を無料の「マテリアルボードサービス」で完結でき、関係者とリアルタイムに共有が出来る様になります。更にArch-LOGにご登録いただいている商品はマテリアルボード内で、その商品をクリックするだけで全ての商品情報を確認することができます。簡単に何パターンでも制作でき、パネルのメンテナンスも必要ありません。又、マテリアルボードと共に「BIMサービス」上でレンダリングすることで、デジタル一元化したクオリティーの高いプレゼンテーションを展開することができます。
        </Article>
      </ContainerRight>
    </LeftSectionContainer>
  ),
)

/**
 */
export const ThirdSection = withDefaultRenderComponent(
  ({
    components: {
      RightSectionContainer = DefaultComponents.RightSectionContainer,
      ContainerLeft = DefaultComponents.ContainerLeft,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <RightSectionContainer>
      <Images.ThirdSection />
      <ContainerLeft>
        <Headline>SOLUTION</Headline>
        <Brief>Arch-LOGが提供する次世代BIM</Brief>
        <Article>
          Arch-LOGでは、BIM環境に最適化した「BIMサービス」を展開致します。現在、建材メーカー様だけでは克服できない、BIMに対する色々な課題があります。Arch-LOGではそれらすべての課題を克服する「BIMサービス」をご利用頂く皆様に提供いたします。まず、ビル建築におけるBIMソフトウェア、ARCHICAD、Revit及びSketchUPに対応済みなのはもちろん、その他のソフトウェアに関しても続々対応していきます。更に、BIMオブジェクトを軽量化する技術、そして軽量化したオブジェクトと連動してクラウド上でリアルタイムに高画質レンダリングする技術は、世界に於いても弊社の技術が大変注目を集めております。Arch-LOGをご利用いただくことで、BIM設計を統合設計化し運用するのはもちろん、フォトリアルなレンダリングも無料でできます。
        </Article>
      </ContainerLeft>
    </RightSectionContainer>
  ),
)

/**
 */
export const FourthSection = withDefaultRenderComponent(
  ({
    components: {
      LeftSectionContainer = DefaultComponents.LeftSectionContainer,
      ContainerRight = DefaultComponents.ContainerRight,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <LeftSectionContainer>
      <Images.FourthSection />
      <ContainerRight>
        <Headline>COST</Headline>
        <Brief>経済合理性の確保と新たな価値創出</Brief>
        <Article>
          Arch-LOGは建築業界に関わる様々な人々の利便性、業務効率向上の一翼を担います。Arch-LOGで提供するサービスをご利用いただくことで、電話、FAX、メール、建材カタログの管理、出張等の交通費、プレゼンテーションコスト、CG制作などの経費や外注費等、様々な間接コストの圧縮が計れます。そして何より、BIMと言う新しいワークフローに対する新しいソリューションを確立する事で新しい事業モデルも創出され、その中にビジネスを見出すことも可能になります。総じて、プロジェクト管理における品質の向上を遂げるとともに、間接経費を圧縮し、新しいビジネス環境の創出が可能となります。それがArch-LOGです。
        </Article>
      </ContainerRight>
    </LeftSectionContainer>
  ),
)
