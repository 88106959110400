import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { Default as AboutSectionContent } from './AboutSection'

import * as Literals from './Literals'
import * as DefaultComponents from './DefaultComponents'
import * as styles from './styles.module.scss'

/**
 */
const BackgroundImage = () => <div className={styles.BackgroundImage}></div>

/**
 */
const Headline = withDefaultRenderComponent(
  ({ components: { Headline = DefaultComponents.Headline } = {} }) => (
    <Headline>
      <Literals.Headline />
    </Headline>
  ),
)

/**
 */
const Description = withDefaultRenderComponent(
  ({ components: { Description = DefaultComponents.Description } = {} }) => (
    <Description>
      <Literals.Description />
    </Description>
  ),
)

/**
 */
const Header = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.HeaderContainer,
      TextArea = DefaultComponents.TextArea,
      Headline: headlineComponents,
      Description: descriptionComponents,
    } = {},
  }) => (
    <Container>
      <BackgroundImage />
      <TextArea>
        <Headline components={headlineComponents} />
        <Description components={descriptionComponents} />
      </TextArea>
    </Container>
  ),
)

/**
 */
const Body = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.BodyContainer,
      AboutSectionContent: aboutSectionContentComponents,
    } = {},
  }) => (
    <Container>
      <AboutSectionContent components={aboutSectionContentComponents} />
    </Container>
  ),
)

/**
 */
export const Default = ({
  components: {
    Container = DefaultComponents.Container,
    Header: headerComponents,
    Body: bodyComponents,
  } = {},
}) => (
  <Container>
    <Header components={headerComponents} />
    <Body components={bodyComponents} />
  </Container>
)
