import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Images from '../Images'
import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = withDefaultRenderComponent(
  ({
    components: {
      RightSectionContainer = DefaultComponents.RightSectionContainer,
      ContainerLeft = DefaultComponents.ContainerLeft,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <RightSectionContainer>
      <Images.FirstSection />
      <ContainerLeft>
        <Headline>MANAGEMENT</Headline>
        <Brief>
          Evolution of catalogs and centralized management of building material
          information
        </Brief>
        <Article>
          Recently, the variety of construction materials catalogs has been
          increasing rapidly. I'm sorry. However, depending on the number of
          manufacturers in the same industry, each For products Confusion due to
          different expressions and explanations from different angles was
          unavoidable, causing confusion for the designers and builders who were
          users, and questioning the rational use of the catalog. The subject is
          born. Also, from the limits of expression in the catalog , W E B
          Inevitably operated in a double structure with the catalog is .
          Therefore, from now on It is also true that there is a problem with
          the product Brief and product data handling. For individual product
          data at the manufacturer By using Arch-LOG, you can dramatically solve
          the problems that hinder the handling and improvement of user
          convenience. The centralized management of the building material data
          for each project is based on the data. The collection of data will be
          archived over the years. Build a life cycle Through this, "centralized
          management of building material data" is possible, expanding the
          possibilities for future FM utilization.
        </Article>
      </ContainerLeft>
    </RightSectionContainer>
  ),
)

/**
 */
export const SecondSection = withDefaultRenderComponent(
  ({
    components: {
      LeftSectionContainer = DefaultComponents.LeftSectionContainer,
      ContainerRight = DefaultComponents.ContainerRight,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <LeftSectionContainer>
      <Images.SecondSection />
      <ContainerRight>
        <Headline>PRESENTATION</Headline>
        <Brief>Digital presentation reform</Brief>
        <Article>
          Nowadays, when making a design design proposal, a material sample is
          used. Cut the pull and attach it to the panel, mate like an analog
          Create a real board The current situation is. It takes a lot of time
          and effort each time you make a re-proposal, the same work is
          repeated, and it becomes an analog work, so a simple human error There
          is always the risk of. By using Arch-LOG, these operations can be
          completed with a free "material board service". You will be able to
          share in real time with related parties. Furthermore, the products
          registered in Arch-LOG are on the material board, and their quotients.
          You can check all product information just by clicking the product.
          You can easily create any number of patterns, and panel maintenance is
          also required. is not . Also, together with the material board, "BIM
          service" By linking on the above, the digital has been unified. You
          can develop a high quality presentation.
        </Article>
      </ContainerRight>
    </LeftSectionContainer>
  ),
)

/**
 */
export const ThirdSection = withDefaultRenderComponent(
  ({
    components: {
      RightSectionContainer = DefaultComponents.RightSectionContainer,
      ContainerLeft = DefaultComponents.ContainerLeft,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <RightSectionContainer>
      <Images.ThirdSection />
      <ContainerLeft>
        <Headline>SOLUTION</Headline>
        <Brief> Next-generation BIM provided by Arch-LOG</Brief>
        <Article>
          Arch-LOG will develop "BIM service" optimized for BIM environment.
          Currently, for BIM, which cannot be overcome by building material
          manufacturers alone There are various issues to be solved. Arch-LOG
          will provide to all users of the "BIM service" that overcomes all of
          these issues. First of all, BIM software for building construction,
          ARCHICAD, Revit and SketchUP are supported, as well as other software.
          We will continue to respond to these issues. In addition, BIM Objek
          With the technology to reduce the weight and the vehicle with the
          weight reduction Continuously on the cloud Our technology is
          attracting a great deal of attention in the world as a technology for
          rendering high-quality images in real time. Use Arch-LOG In most
          cases, it is also possible to integrate and operate the BIM design. Of
          course, formal lending is also available free of charge.
        </Article>
      </ContainerLeft>
    </RightSectionContainer>
  ),
)

/**
 */
export const FourthSection = withDefaultRenderComponent(
  ({
    components: {
      LeftSectionContainer = DefaultComponents.LeftSectionContainer,
      ContainerRight = DefaultComponents.ContainerRight,
      Headline = DefaultComponents.Headline,
      Brief = DefaultComponents.Brief,
      Article = DefaultComponents.Article,
    } = {},
  }) => (
    <LeftSectionContainer>
      <Images.FourthSection />
      <ContainerRight>
        <Headline>COST</Headline>
        <Brief>Ensuring economic rationality and creating new value</Brief>
        <Article>
          Arch-LOG plays a role in improving the convenience and operational
          efficiency of various people involved in the construction industry.
          Use the services provided by Arch-LOG Pipes for telephones, fax
          machines, fax machines, building materials catalogs, etc.
          Transportation expenses such as science and extension, presentation
          cost, CG system Expenses such as production and outsourcing Various
          indirect costs such as costs can be reduced. And above all, establish
          a new solution for a new workflow called BIM A new business model was
          also created by the matter, and a business was created in it. It is
          also possible to find it. As a whole, project tube Improvement of
          reasonable quality At the same time, it is possible to reduce indirect
          costs and create a new business environment. That is Arch-LOG.
        </Article>
      </ContainerRight>
    </LeftSectionContainer>
  ),
)
