import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Headline = translated('app')('AboutPage.Headline')

/**
 */
export const Description = translated('app')('AboutPage.Description')
