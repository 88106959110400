import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

/**
 */
export const Title = ({ className, imgClassName }) => (
  <StaticImage
    className={className}
    imgClassName={imgClassName}
    src="../../images/about/title.png"
    alt="image"
    quality={100}
  />
)

/**
 */
export const First = ({ className }) => (
  <StaticImage
    className={className}
    src="../../images/about/management.jpg"
    alt="image"
    quality={100}
  />
)

/**
 */
export const Second = ({ className }) => (
  <StaticImage
    className={className}
    src="../../images/about/presentation.jpg"
    alt="image"
    quality={100}
  />
)

/**
 */
export const Third = ({ className }) => (
  <StaticImage
    className={className}
    src="../../images/about/solution.jpg"
    alt="image"
    quality={100}
  />
)

/**
 */
export const Fourth = ({ className }) => (
  <StaticImage
    className={className}
    src="../../images/about/about_cost.jpg"
    alt="image"
    quality={100}
  />
)
