import React from 'react'

export const ForMaker = () => (
  <>
    <div
      className="wistia_responsive_padding"
      style={{
        padding: '56.25% 0 0 0',
        width: '100%',
        position: 'relative',
        height: '100%',
      }}
    >
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <span
          className="wistia_embed wistia_async_m4fcai4x3k popover=true popoverAnimateThumbnail=true videoFoam=true"
          style={{
            display: 'inline-block',
            height: '100%',
            position: 'relative',
            width: '100%',
          }}
        >
          &nbsp;
        </span>
      </div>
    </div>
  </>
)

export const ForMakerScript = () => {
  return (
    <>
      <script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        type="application/javascript"
      ></script>
      <script
        src="https://fast.wistia.com/embed/medias/m4fcai4x3k.jsonp"
        type="application/javascript"
      ></script>
    </>
  )
}
