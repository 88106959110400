import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const HeadlineContainer = ({ children }) => (
  <div className={styles.Headline}>
    <h3 className={styles.Headline__Text}>{children}</h3>
  </div>
)

/**
 */
export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

/**
 */
export const HeadlineContent = ({ children }) => {
  return <div className={styles.HeadlineContent}>{children}</div>
}
