import React from 'react'

import * as Images from 'components/Images/About'
import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

import * as DefaultComponents from './DefaultComponents'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
const Headline = withDefaultRenderComponent(
  ({
    components: { Container = DefaultComponents.HeadlineContainer } = {},
  }) => {
    return (
      <Container>
        <Literals.Headline />
      </Container>
    )
  },
)

/**
 */
const TitleLogo = () => (
  <Images.Title
    className={styles.TitleLogo}
    imgClassName={styles.TitleLogo}
    alt="about title logo"
  />
)

/**
 */
const DescriptionJa = withDefaultRenderComponent(
  ({ components: { Description = DefaultComponents.Description } = {} }) => (
    <Description>
      <Literals.DescriptionJa />
    </Description>
  ),
)

/**
 */
const DescriptionEn = withDefaultRenderComponent(
  ({ components: { Description = DefaultComponents.Description } = {} }) => (
    <Description>
      <Literals.DescriptionEn />
    </Description>
  ),
)

/**
 */
const useLanguageText = (lang) => {
  switch (lang) {
    case 'en':
      return DescriptionEn
    default:
      return DescriptionJa
  }
}

/**
 */
const Description = withLanguage(({ language }) => {
  const Text = useLanguageText(language)

  return <Text />
})

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.Container,
      Headline: headlineComponents,
      HeadlineContent = DefaultComponents.HeadlineContent,
    } = {},
  }) => (
    <Container>
      <Headline components={headlineComponents} />
      <HeadlineContent>
        <TitleLogo />
        <Description />
      </HeadlineContent>
    </Container>
  ),
)
