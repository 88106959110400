import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'
import { Default as SectionHeadline } from './SectionHeadline'

import * as SectionComponents from './Sections'
import * as DefaultComponents from './DefaultComponents'

/**
 */
const Header = withDefaultRenderComponent(
  ({ components: { Container = DefaultComponents.HeaderContainer } = {} }) => (
    <Container>
      <SectionHeadline />
    </Container>
  ),
)

/**
 */
const Body = withDefaultRenderComponent(
  ({
    components: { Container = DefaultComponents.BodyContainer } = {},
    children,
  }) => <Container>{children}</Container>,
)

const Sections = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.SectionContainer,
      FirstSection: firstSectionComponents,
      SecondSection: secondSectionComponents,
      ThirdSection: thirdSectionComponents,
      FourthSection: fourthSectionComponents,
    } = {},
  }) => (
    <Container>
      <SectionComponents.FirstSection components={firstSectionComponents} />
      <SectionComponents.SecondSection components={secondSectionComponents} />
      <SectionComponents.ThirdSection components={thirdSectionComponents} />
      <SectionComponents.FourthSection components={fourthSectionComponents} />
    </Container>
  ),
)

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    components: {
      Container = DefaultComponents.Container,
      Header: headerComponents,
      Body: bodyComponents,
      Sections: sectionsComponents,
    } = {},
  }) => (
    <Container>
      <Header components={headerComponents} />
      <Body components={bodyComponents}>
        <Sections components={sectionsComponents} />
      </Body>
    </Container>
  ),
)
