import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Headline = translated('app')('AboutPage.AboutSection.Headline')

/**
 */
export const DescriptionJa = () => (
  <>
    それは、BIMに関する実務上の悩みをすべて解決する唯一のソリューション。
    建材にまつわるあらゆる業務をWEBベースの環境で完結。建材検索、サンプル/資料請求、マテリアルボード作成、BIMレンダリングetc...皆様の不安をArch-LOGが全て解決します。
  </>
)

/**
 */
export const DescriptionEn = () => (
  <>
    It's the only solution to all the practical worries about BIM. Complete all
    business related to building materials in a web-based environment. Building
    material search, sample / material request, Material board creation, BIM
    rendering ETC ... ARCH-LOG will solve all your anxieties.
  </>
)
