import React from 'react'

import * as Images from 'components/Images/About'

import * as styles from './styles.module.scss'

/**
 */
export const FirstSection = () => {
  return <Images.First className={styles.ImageLeft} alt="first section" />
}

/**
 */
export const SecondSection = () => {
  return <Images.Second className={styles.ImageRight} alt="second section" />
}

/**
 */
export const ThirdSection = () => {
  return <Images.Third className={styles.ImageLeft} alt="third section" />
}

/**
 */
export const FourthSection = () => {
  return <Images.Fourth className={styles.ImageRight} alt="four section" />
}
