import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const HeaderContainer = ({ children }) => (
  <div className={styles.HeaderContainer}>{children}</div>
)

/**
 */
export const BodyContainer = ({ children }) => (
  <div className={styles.BodyContainer}>{children}</div>
)

/**
 */
export const Headline = ({ children }) => (
  <h2 className={styles.Headline}>{children}</h2>
)

/**
 */
export const Description = ({ children }) => (
  <p className={styles.Description}>{children}</p>
)

/**
 */
export const TextArea = ({ children }) => (
  <div className={styles.TextArea}>{children}</div>
)
