import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const ContainerLeft = ({ children }) => (
  <div className={styles.ContainerLeft}>{children}</div>
)

/**
 */
export const ContainerRight = ({ children }) => (
  <div className={styles.ContainerRight}>{children}</div>
)

/**
 */
export const Headline = ({ children }) => (
  <h3 className={styles.Headline}>{children}</h3>
)

/**
 */
export const Brief = ({ children }) => (
  <h4 className={styles.Brief}>{children}</h4>
)

/**
 */
export const Article = ({ children }) => (
  <p className={styles.Article}>{children}</p>
)

/**
 */
export const LeftSectionContainer = ({ children, ...props }) => {
  return (
    <div className={styles.LeftSectionContainer} {...props}>
      {children}
    </div>
  )
}

/**
 */
export const RightSectionContainer = ({ children, ...props }) => {
  return (
    <div className={styles.RightSectionContainer} {...props}>
      {children}
    </div>
  )
}
